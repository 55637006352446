import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setColorModal, setModal} from "../store/globals/actions";
import '../../css/modal.css';
import Button from "./Button";


class ModalAbout extends Component {

    closeModal = () => {
        this.props.dispatch(setModal(''));
    };

    closeModalOwner = (e) => {
        if (e.target.classList.contains('ModalOwner')) {
            this.props.dispatch(setModal(''));
        }
    };

    componentDidMount = () => {

    };

    render() {
        return (
            <div className="ModalOwner" onClick={ (e) => this.closeModalOwner(e) }>
                <div className="Modal Modal__about">
                    <div className="White-block w-100">
                        <div className="Quote">
                            <div className="Quote__title">Что такое QR-код?</div>
                            <div className="Quote__text">
                                <p>QR-код — по сути, обычный штрих-код, только выглядит иначе. Как и штрихкоды на товарах, QR-код содержит информацию, но какую именно — Вы решаете сами. Это может быть Ваша страница ВКонтакте, сайт, приложение, контактные данные, геолокация, настройки Wi-Fi — всё, что нужно для Ваших задач. Любые материалы, которые Вы добавите в QR-код, Ваши клиенты смогут открыть и посмотреть парой касаний.</p>
                            </div>
                        </div>
                        <div className="Quote">
                            <div className="Quote__title">Как отсканировать QR-код?</div>
                            <div className="Quote__text">
                                <p>Чтобы получить информацию со штрихкода, продавцы используют специальный сканер. Вашим клиентам не нужны особые устройства — достаточно открыть камеру в мобильном приложении ВКонтакте и навести её на QR-код. Приложение распознает его и предложит посмотреть сведения, которые Вы добавили.</p>
                            </div>
                        </div>
                        <div className="Quote">
                            <div className="Quote__title">Как создать QR-код?</div>
                            <div className="Quote__text">
                                <p>Применение QR-кода не ограничено никакими рамками — это полезный инструмент и для бизнеса, и для личных целей. Разместить его можно где угодно: на товаре, кассе, открытке, наружной рекламе, слайде в презентации, визитке, футболке и любых других носителях.</p>
                            </div>
                        </div>
                        <div className="Quote">
                            <div className="Quote__title">Как использовать QR-код?</div>
                            <div className="Quote__text">
                                <p>Применение QR-кода не ограничено никакими рамками — это полезный инструмент и для бизнеса, и для личных целей. Разместить его можно где угодно: на товаре, кассе, открытке, наружной рекламе, слайде в презентации, визитке, футболке и любых других носителях.</p>
                                <p>QR-коды, созданные в конструкторе ВКонтакте, ещё и отличный инструмент аналитики. На этом же сайте Вы сможете посмотреть различную статистику: сколько людей отсканировали QR-код, сколько перешли по ссылке, сколько добавили в телефон напоминание о событии или Ваши контактные данные. С помощью этих данных Вы легко проанализируете эффективность рекламы.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(ModalAbout);