import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/block-link.css';
import {
    setAdvancedLink, setAdvancedTitle, setAdvancedTopIcon, setAdvancedTopIconLoaded, setAdvancedWithImageAndText,
    setLinkUrl, setLookBgImage, setLookBgLoaded
} from "../store/settings/actions";
import { UPDATE_TIME } from "../services/_locals";
import {googleEvent, toBase64, yandexEvent} from "../services/_functions";
import {getVkUserInfo} from "../services/axios";
import {SITE_URL} from "../services/_globals";


class BlockLink extends Component {

    state = {
        timeout: undefined,
    };

    updateGoogleTimeout;

    changeUrlHandler = async(e) => {
        const val = e.target.value;

        clearTimeout(this.state.timeout);
        this.setState({
            timeout:
                setTimeout(() => {
                    this.props.dispatch(setLinkUrl(val));
                    this.getUser(val);
                }, UPDATE_TIME)
        });

        clearTimeout(this.updateGoogleTimeout);
        this.updateGoogleTimeout = setTimeout(() => {
            googleEvent('Edit', 'Link', 'New_link');
            yandexEvent('edit_new_link');
        }, 2500);
    };

    getUser = async(val) => {
        if (this.parseUrl(val) !== false) {
            const vkId = this.parseUrl(val).replace('/', '');

            const isLarge = this.props.lookType === 4;
            const getInfoResult = await getVkUserInfo(vkId, isLarge);

            if (getInfoResult.result === 'success') {
                this.props.dispatch(setAdvancedWithImageAndText(true));
                const wholeName = (getInfoResult.data.first_name + ' ' + getInfoResult.data.last_name).length > 18 ? getInfoResult.data.last_name : getInfoResult.data.first_name + ' ' + getInfoResult.data.last_name;
                this.props.dispatch(setAdvancedTitle(wholeName));
                this.props.dispatch(setAdvancedLink('@' + getInfoResult.data.domain));

                // Для 2 и 3 типа
                toBase64(getInfoResult.filename_small, result => {
                    this.props.dispatch(setAdvancedTopIcon(result));
                    this.props.dispatch(setAdvancedTopIconLoaded(true));
                });

                // Для 4 типа
                toBase64(getInfoResult.filename_large, result => {
                    this.props.dispatch(setLookBgImage(result));
                    this.props.dispatch(setLookBgLoaded(true));
                });
            }
        }
    };

    parseUrl = (url) => {
        if (url.indexOf('https://vk.com/') !== -1 || url.indexOf('https://m.vk.com/') !== -1 || url.indexOf('m.vk.com/') !== -1 || url.indexOf('vk.com/') !== -1) {
            const startPos = url.indexOf('vk.com/') + 7;
            return url.substr(startPos, url.length);
        }
        return false;
    };

    render() {
        return (
            <div className="White-block">
                <div className="White-block__info">
                    <p>Чтобы самому сделать QR-код со ссылкой, введите адрес в QR-генератор и сохраните готовое изображение в подходящем для Вас формате — например, в векторе для печати.</p>
                    <p>Благодаря QR-коду клиентам не придётся вводить вручную ссылку на Вашу страницу ВКонтакте, сайт или любой другой онлайн-ресурс. Достаточно отсканировать QR-код.</p>
                </div>
                <div className="Form-input">
                    <div className="Form-input__name">URL-адрес</div>
                    <input maxLength={ 110 } type="text" placeholder="Введите адрес" onChange={ this.changeUrlHandler } defaultValue={ this.props.linkUrl } />
                </div>
                {/*<div className="Form-checkbox">*/}
                    {/*<input type="checkbox" id="dynamic-link" value=""/>*/}
                    {/*<label htmlFor="dynamic-link">Динамическая ссылка</label>*/}
                    {/*<div className="About-url">*/}
                        {/*<div className="About-url__text">*/}
                            {/*<p>Вы сможете менять ссылку динамического QR-кода после его создания и получать полную статистику его сканирования.</p>*/}
                            {/*<p>Для создания динамического QR-кода будет использован сервис сокращения ссылок <a href="#">vk.cc</a></p>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        linkUrl: state.settings.link.url,
        lookType: state.settings.look.selectedType,
    };
};

export default connect(mapStateToProps)(BlockLink);
