import React, { Component } from 'react';
import '../../css/button.css';


export default class Button extends Component {
    render() {
        let classes = ['Button'];
        if (this.props.rounded !== undefined) {
            classes.push('Button--rounded')
        }
        classes.push(this.props.className);

        if (this.props.color !== undefined) {
            switch (this.props.color) {
                case 'transparent':
                    classes.push('Button--transparent');
                    break;
                case 'white':
                    classes.push('Button--white');
                    break;
                case 'blank':
                    classes.push('Button--blank');
                    break;
                case 'light':
                    classes.push('Button--light');
                    break;
            }
        }


        return (
            <button onClick={ () => this.props.onClick() } className={ classes.join(' ') }>{ this.props.children }</button>
        );
    }
}