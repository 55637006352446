import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/custom-select.css';


class CustomSelect extends Component {

    state = {
        focused: false,
        placeholder: true,
        text: (this.props.value !== undefined ? this.props.value : ''),
    };

    onBlur = () => {
        this.setState({ focused: false });
    };

    onFocus = () => {
        this.setState(prevState => ({ focused: !prevState.focused }));
    };

    fillInput = (text) => {
        this.changeInput(text);
    };

    changeInput = (value) => {
        this.setState({
            text: value,
            placeholder: false,
            focused: false,
        });
        if (this.props.changeFunc !== undefined) this.props.changeFunc(value);
    };

    renderList = () => {
        let list = [];
        let key = Math.random() * 10000000000;
        for (let i = 0; i < this.props.options.length; i++) {
            list.push(<div key={ "inp-sel-" + key + '-' +i } className="Custom-select__list_option" onClick={ () => this.fillInput(this.props.options[i]) }>{ this.props.options[i] }</div>)
        }
        return list;
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ focused: false });
        }
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    render() {
        let innerPlaceholder = <span className="Custom-select__input-block_text__placeholder">{ this.props.placeholder }</span>;

        return (
            <div className="Custom-select" ref={ this.setWrapperRef }>
                <div className="Custom-select__input-block">
                    <div className={ "Custom-select__input-block_text" + (this.state.focused ? ' Custom-select__input-block_text--open' : '') } onClick={ this.onFocus }>
                        { this.state.placeholder && innerPlaceholder }
                        { !this.state.placeholder && this.state.text }
                    </div>
                    <div className="Custom-select__input-block_icon"/>
                </div>
                <div className={ "Custom-select__list" + (this.state.focused ? ' Custom-select__list--open' : '') }>
                    { this.renderList() }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(CustomSelect);