import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setWifiName, setWifiPass, setWifiType} from "../store/settings/actions";
import {UPDATE_TIME} from "../services/_locals";
import {googleEvent, yandexEvent} from "../services/_functions";


class BlockWifi extends Component {

    state = {
        timeout: undefined,
    };

    updateGoogleTimeoutName;
    updateGoogleTimeoutPass;
    updateGoogleTimeoutType;

    changeNameHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);
        this.setState({ timeout: setTimeout(() => this.props.dispatch(setWifiName(val)), UPDATE_TIME) });

        clearTimeout(this.updateGoogleTimeoutName);
        this.updateGoogleTimeoutName = setTimeout(() => {
            googleEvent('Edit', 'Wi-Fi', 'Data_network');
            yandexEvent('edit_wifi_data_network');
        }, 2500);
    };

    changePassHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);
        this.setState({ timeout: setTimeout(() => this.props.dispatch(setWifiPass(val)), UPDATE_TIME) });

        clearTimeout(this.updateGoogleTimeoutPass);
        this.updateGoogleTimeoutPass = setTimeout(() => {
            googleEvent('Edit', 'Wi-Fi', 'Data_password');
            yandexEvent('edit_wifi_data_password');
        }, 2500);
    };

    changeWifiTypeHandler = (e) => {
        this.props.dispatch(setWifiType(+e.target.value));

        clearTimeout(this.updateGoogleTimeoutType);
        this.updateGoogleTimeoutType = setTimeout(() => {
            googleEvent('Edit', 'Wi-Fi', 'Data_secure');
            yandexEvent('edit_wifi_data_secure');
        }, 2500);
    };

    blurNameHandler = (e) => {
        clearTimeout(this.state.timeout);
        if (e.target.value !== this.props.name) this.props.dispatch(setWifiName(e.target.value));
    };

    blurPassHandler = (e) => {
        clearTimeout(this.state.timeout);
        if (e.target.value !== this.props.pass) this.props.dispatch(setWifiPass(e.target.value));
    };

    render() {
        return (
            <div className="White-block">
                <div className="White-block__info">
                    <p>Избавьте клиентов от необходимости искать пароль от Wi-Fi, а затем вводить его вручную. Сгенерируйте QR-код — и гости смогут подключиться к сети быстро и безопасно.</p>
                    <p>Чтобы создать код через QR-генератор ВКонтакте, укажите логин и пароль от Wi-Fi в соответствующем поле и скачайте готовое изображение.</p>
                    <p>Когда клиент наведёт камеру приложения VK на этот QR-код, ему будет достаточно нажать «Подключиться», чтобы начать использовать Вашу сеть.</p>
                </div>
                <div className="Form-input">
                    <div className="Form-input__name">Имя сети</div>
                    <input type="text" placeholder="Введите имя сети..." onChange={ this.changeNameHandler } defaultValue={ this.props.name } onBlur={ this.blurNameHandler } />
                </div>
                <div className="Form-input">
                    <div className="Form-input__name">Пароль</div>
                    <input type="password" placeholder="Введите пароль..." onChange={ this.changePassHandler } defaultValue={ this.props.pass } onBlur={ this.blurPassHandler } />
                </div>
                <div className="Group-form">
                    <div className="Group-form__name">Тип шифрования</div>
                    <div className="Group-form__radio flex-direction-column">
                        <div className="Form-radio">
                            <input type="radio" id="type-encryption-1" name="radio-box" value="1" checked={ this.props.wifiType === 1 } onChange={ this.changeWifiTypeHandler } />
                            <label htmlFor="type-encryption-1">WPA/WPA2</label>
                        </div>
                        <div className="Form-radio">
                            <input type="radio" id="type-encryption-2" name="radio-box" value="2" checked={ this.props.wifiType === 2 } onChange={ this.changeWifiTypeHandler }/>
                            <label htmlFor="type-encryption-2">WEP</label>
                        </div>
                        <div className="Form-radio">
                            <input type="radio" id="type-encryption-3" name="radio-box" value="3" checked={ this.props.wifiType === 3 } onChange={ this.changeWifiTypeHandler }/>
                            <label htmlFor="type-encryption-3">Без шифрования</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        name: state.settings.wifi.name,
        pass: state.settings.wifi.pass,
        wifiType: state.settings.wifi.wifiType,
    };
};

export default connect(mapStateToProps)(BlockWifi);
