import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import '../../css/header.css';
import Button from "./Button";
import history from '../../history';
import {setModal} from "../store/globals/actions";
import ModalAbout from "./ModalAbout";


class HeaderSmall extends Component {

    openMain = () => {
        this.props.dispatch(push('/link'));
    };

    render() {
        return (
            <React.Fragment>
                <div className="HeaderSmall">
                    <div className="container">
                        <div className="HeaderSmall__top-block">
                            <div className="HeaderSmall__left">
                                <div className="SmallTop-logo" onClick={ this.openMain }>
                                    <div className="SmallTop-logo__icon"/>
                                    <div className="SmallTop-logo__title">QR-генератор</div>
                                </div>
                            </div>
                            <div className="HeaderSmall__right">
                                <div className="HeaderSmall__right_title">{ this.props.title }</div>
                                {/*<div className="HeaderSmall__right_account-name">Сергей</div>*/}
                                {/*<div className="HeaderSmall__right_account-ava"/>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="back-fixed" onClick={ this.openMain }>
                    <div className="back-label">
                        <div className="icon"/>
                        <div className="text">Назад</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        selectedUrl: state.globals.selectedUrl,
    };
};

export default connect(mapStateToProps)(HeaderSmall);