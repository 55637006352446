import React, { Component } from 'react';
import { connect } from 'react-redux';


export default class BlockAbout extends Component {

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll = (e) => {
        let top = e.srcElement.documentElement.scrollTop;
        const topLabel = document.querySelector('.back-fixed');
        console.log(top);
        topLabel.style.top = Math.max(0, 60 - top) + 'px';
    };

    render() {
        return (
            <div className="White-block w-100">
                <div className="Quote">
                    <div className="Quote__title">Что такое QR-код?</div>
                    <div className="Quote__text">
                        <p>QR-код — по сути, обычный штрих-код, только выглядит иначе. Как и штрихкоды на товарах, QR-код содержит информацию, но какую именно — Вы решаете сами. Это может быть Ваша страница ВКонтакте, сайт, приложение, контактные данные, геолокация, настройки Wi-Fi — всё, что нужно для Ваших задач. Любые материалы, которые Вы добавите в QR-код, Ваши клиенты смогут открыть и посмотреть парой касаний.</p>
                    </div>
                </div>
                <div className="Quote">
                    <div className="Quote__title">Как отсканировать QR-код?</div>
                    <div className="Quote__text">
                        <p>Чтобы получить информацию со штрихкода, продавцы используют специальный сканер. Вашим клиентам не нужны особые устройства — достаточно открыть камеру в мобильном приложении ВКонтакте и навести её на QR-код. Приложение распознает его и предложит посмотреть сведения, которые Вы добавили.</p>
                    </div>
                </div>
                <div className="Quote">
                    <div className="Quote__title">Как создать QR-код?</div>
                    <div className="Quote__text">
                        <p>Просто введите нужную ссылку или информацию в онлайн-генератор кодов ВКонтакте, выберите один из макетов — и скачайте готовый QR-код в удобном для Вас формате. Генерация QR-кода займёт меньше минуты, а использовать его можно для самых разных задач.</p>
                    </div>
                </div>
                <div className="Quote">
                    <div className="Quote__title">Как использовать QR-код?</div>
                    <div className="Quote__text">
                        <p>Применение QR-кода не ограничено никакими рамками — это полезный инструмент и для бизнеса, и для личных целей. Разместить его можно где угодно: на товаре, кассе, открытке, наружной рекламе, слайде в презентации, визитке, футболке и любых других носителях.</p>
                    </div>
                </div>
            </div>
        );
    }

}
