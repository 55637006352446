import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/block-event.css';
import '../../css/calendar.css';
import {
    setEventDescription, setEventEndDate, setEventEndTimeHours, setEventEndTimeMinutes,
    setEventStartDate,
    setEventStartTimeHours,
    setEventStartTimeMinutes,
    setEventTitle
} from "../store/settings/actions";
import { UPDATE_TIME } from "../services/_locals";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
import CustomSelect from "../components/CustomSelect";
import moment from "moment";
import {googleEvent, yandexEvent} from "../services/_functions";
registerLocale('ru', ru);

class BlockEvent extends Component {

    state = {
        typing: false,
        timeout: undefined,
        startHours: this.props.eventStartTimeHours,
        startMinutes: this.props.eventStartTimeMinutes,
        endHours: this.props.eventEndTimeHours,
        endMinutes: this.props.eventStartTimeMinutes,
        isStartDateError: false,
        isEndDateError: false,
    };

    updateGoogleTimeoutName;
    updateGoogleTimeoutLink;
    updateGoogleTimeoutTimeStart;
    updateGoogleTimeoutTimeEnd;
    updateGoogleTimeoutDateStart;
    updateGoogleTimeoutDateEnd;

    changeHanler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);

        switch (e.target.id) {
            case 'event-title':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setEventTitle(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutName);
                this.updateGoogleTimeoutName = setTimeout(() => {
                    googleEvent('Edit', 'Event', 'Data_name');
                    yandexEvent('edit_event_data_name');
                }, 2500);
                break;

            case 'event-start-time-minutes':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setEventStartTimeMinutes(val)), UPDATE_TIME) });
                break;

            case 'event-end-time-minutes':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setEventEndTimeMinutes(val)), UPDATE_TIME) });
                break;

            case 'event-description':
                this.setState({ timeout: setTimeout(() => this.props.dispatch(setEventDescription(val)), UPDATE_TIME) });
                clearTimeout(this.updateGoogleTimeoutLink);
                this.updateGoogleTimeoutLink = setTimeout(() => {
                    googleEvent('Edit', 'Event', 'Data_link');
                    yandexEvent('edit_event_data_link');
                }, 2500);
                break;
        }
    };

    blurHandler = (e) => {
        clearTimeout(this.state.timeout);
        switch (e.target.id) {
            case 'event-title':
                if (e.target.value !== this.props.eventTitle) this.props.dispatch(setEventTitle(e.target.value));
                break;

            case 'event-start-time-hours':
                if (e.target.value !== this.props.eventStartTimeHours) this.props.dispatch(setEventStartTimeHours(e.target.value));
                break;

            case 'event-start-time-minutes':
                if (e.target.value !== this.props.eventStartTimeMinutes) this.props.dispatch(setEventStartTimeMinutes(e.target.value));
                break;

            case 'event-end-time-hours':
                if (e.target.value !== this.props.eventEndTimeHours) this.props.dispatch(setEventEndTimeHours(e.target.value));
                break;

            case 'event-end-time-minutes':
                if (e.target.value !== this.props.eventEndTimeMinutes) this.props.dispatch(setEventEndTimeMinutes(e.target.value));
                break;

            case 'event-description':
                if (e.target.value !== this.props.eventDescription) this.props.dispatch(setEventDescription(e.target.value));
                break;
        }
    };

    handleChangeDateStart = async(date) => {
        if (date === null) date = '';
        await this.props.dispatch(setEventStartDate(date));
        this.checkStartDate();
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutDateStart);
        this.updateGoogleTimeoutDateStart = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    handleChangeDateEnd = async(date) => {
        if (date === null) date = '';
        await this.props.dispatch(setEventEndDate(date));
        this.checkStartDate();
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutDateEnd);
        this.updateGoogleTimeoutDateEnd = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    handleChangeHoursStart = async(val) => {
        await this.props.dispatch(setEventStartTimeHours(val));
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutTimeStart);
        this.updateGoogleTimeoutTimeStart = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    handleChangeHoursEnd = async(val) => {
        await this.props.dispatch(setEventEndTimeHours(val));
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutTimeEnd);
        this.updateGoogleTimeoutTimeEnd = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    handleChangeMinutesStart = async(val) => {
        await this.props.dispatch(setEventStartTimeMinutes(val));
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutTimeStart);
        this.updateGoogleTimeoutTimeStart = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    handleChangeMinutesEnd = async(val) => {
        await this.props.dispatch(setEventEndTimeMinutes(val));
        this.checkEndDate();

        clearTimeout(this.updateGoogleTimeoutTimeEnd);
        this.updateGoogleTimeoutTimeEnd = setTimeout(() => {
            googleEvent('Edit', 'Event', 'Data_time');
            yandexEvent('edit_event_data_time');
        }, 2500);
    };

    checkStartDate = () => {
        if (this.props.eventEndDate !== '' && this.props.eventStartDate === '') this.setState({ isStartDateError: true });
        else this.setState({ isStartDateError: false });
    };

    checkEndDate = () => {
        const dateStart = moment(this.props.eventStartDate).add(this.props.eventStartTimeHours, 'hours').add(this.props.eventStartTimeMinutes, 'minutes');
        const dateEnd = moment(this.props.eventEndDate).add(this.props.eventEndTimeHours, 'hours').add(this.props.eventEndTimeMinutes, 'minutes');
        if (dateStart > dateEnd) this.setState({ isEndDateError: true });
        else this.setState({ isEndDateError: false });
    };

    render() {
        const moment = require('moment/min/moment-with-locales');
        moment.locale('ru');
        const momentDateStart = moment().add('hour', 1);
        const momentDateEnd = moment().add('day', 1);
        const phStartHour = momentDateStart.hours();
        const phStartMinute = momentDateStart.format('mm');
        const phStartDate = momentDateStart.subtract('hour', 1).format('D MMMM YYYY');
        const phEndHour = momentDateEnd.hours();
        const phEndMinute = momentDateEnd.format('mm');
        const phEndDate = momentDateEnd.format('D MMMM YYYY');

        return (
            <div className="White-block">
                <div className="White-block__info">
                    <p>Создайте QR-код для мероприятия, чтобы Ваши клиенты могли легко добавить напоминание о нём в календарь телефона.</p>
                    <p>Укажите в QR-генераторе подробности о событии: его название, место проведения, время и дату. Готовый QR-код Вы сможете распространить любым удобным способом: разместить где-то в виде стикера, добавить на листовки или опубликовать онлайн — например, в сообществе ВКонтакте.</p>
                </div>
                <div className="Form-input">
                    <div className="Form-input__name">Название события</div>
                    <input type="text" placeholder="Введите название..." id='event-title' defaultValue={ this.props.eventTitle } onChange={ this.changeHanler } onBlur={ this.blurHandler }/>
                </div>
                <div className="Group-form">
                    <div className="Group-form__name">Время начала</div>
                    <div className={ this.state.isStartDateError ? 'show-error' : '' }>
                        <div className="Group-form__input-calendar Group-form__input-list">
                            <div className="Form-input calendar-date">
                                <DatePicker
                                    selected={ this.props.eventStartDate }
                                    onChange={ this.handleChangeDateStart }
                                    dateFormat="d MMMM yyyy"
                                    locale='ru'
                                    popperPlacement="bottom-end"
                                    placeholderText={ phStartDate }
                                />
                            </div>
                            <span>в</span>
                            <div className="Form-input calendar-time">
                                <CustomSelect
                                    placeholder={ phStartHour }
                                    options={[
                                        0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23
                                    ]}
                                    changeFunc={ this.handleChangeHoursStart }
                                />
                            </div>
                            <span className="time-separator">:</span>
                            <div className="Form-input calendar-time">
                                <CustomSelect
                                    placeholder={ phStartMinute }
                                    options={[
                                        '00','05',10,15,20,25,30,35,40,45,50,55
                                    ]}
                                    changeFunc={ this.handleChangeMinutesStart }
                                />
                            </div>
                        </div>
                        <div className="date-error">Введена неверная дата</div>
                    </div>
                </div>
                <div className="Group-form">
                    <div className="Group-form__name">Время окончания</div>
                    <div className={ this.state.isEndDateError ? 'show-error' : '' }>
                        <div className="Group-form__input-calendar Group-form__input-list">
                            <div className="Form-input calendar-date">
                                <DatePicker
                                    selected={ this.props.eventEndDate }
                                    onChange={ this.handleChangeDateEnd }
                                    dateFormat="d MMMM yyyy"
                                    locale='ru'
                                    popperPlacement="bottom-end"
                                    placeholderText={ phEndDate }
                                />
                            </div>
                            <span>в</span>
                            <div className="Form-input calendar-time">
                                <CustomSelect
                                    placeholder={ phEndHour }
                                    options={[
                                        0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23
                                    ]}
                                    changeFunc={ this.handleChangeHoursEnd }
                                />
                            </div>
                            <span className="time-separator">:</span>
                            <div className="Form-input calendar-time">
                                <CustomSelect
                                    placeholder={ phEndMinute }
                                    options={[
                                        '00','05',10,15,20,25,30,35,40,45,50,55
                                    ]}
                                    changeFunc={ this.handleChangeMinutesEnd }
                                />
                            </div>
                        </div>
                        <div className="date-error">Введена неверная дата</div>
                    </div>
                </div>
                <div className="Form-input">
                    <div className="Form-input__name">Ссылка на событие <span>(необязательно)</span></div>
                    <input type="text" placeholder="Введите ссылку" id='event-description' defaultValue={ this.props.eventDescription } onChange={ this.changeHanler } onBlur={ this.blurHandler }/>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        eventTitle: state.settings.event.title,
        eventStartDate: state.settings.event.startDate,
        eventStartTimeHours: state.settings.event.startTimeHours,
        eventStartTimeMinutes: state.settings.event.startTimeMinutes,
        eventEndDate: state.settings.event.endDate,
        eventEndTimeHours: state.settings.event.endTimeHours,
        eventEndTimeMinutes: state.settings.event.endTimeMinutes,
        eventDescription: state.settings.event.description,
    };
};

export default connect(mapStateToProps)(BlockEvent);