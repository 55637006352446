export const SET_LINK_URL                   = 'SET_LINK_URL';

export const SET_WIFI_NAME                  = 'SET_WIFI_NAME';
export const SET_WIFI_PASS                  = 'SET_WIFI_PASS';
export const SET_WIFI_TYPE                  = 'SET_WIFI_TYPE';

export const SET_CONTACT_FIRST_NAME         = 'SET_CONTACT_FIRST_NAME';
export const SET_CONTACT_MID_NAME           = 'SET_CONTACT_MID_NAME';
export const SET_CONTACT_LAST_NAME          = 'SET_CONTACT_LAST_NAME';
export const SET_CONTACT_POSITION           = 'SET_CONTACT_POSITION';
export const SET_CONTACT_COMPANY            = 'SET_CONTACT_COMPANY';
export const SET_CONTACT_PHONE              = 'SET_CONTACT_PHONE';
export const SET_CONTACT_EMAIL              = 'SET_CONTACT_EMAIL';
export const SET_CONTACT_URL                = 'SET_CONTACT_URL';

export const SET_TEXT_TEXT                  = 'SET_TEXT_TEXT';

export const SET_EVENT_TITLE                = 'SET_EVENT_TITLE';
export const SET_EVENT_START_DATE           = 'SET_EVENT_START_DATE';
export const SET_EVENT_START_TIME_HOURS     = 'SET_EVENT_START_TIME_HOURS';
export const SET_EVENT_START_TIME_MINUTES   = 'SET_EVENT_START_TIME_MINUTES';
export const SET_EVENT_END_DATE             = 'SET_EVENT_END_DATE';
export const SET_EVENT_END_TIME_HOURS       = 'SET_EVENT_END_TIME_HOURS';
export const SET_EVENT_END_TIME_MINUTES     = 'SET_EVENT_END_TIME_MINUTES';
export const SET_EVENT_DESCRIPTION          = 'SET_EVENT_DESCRIPTION';

export const SET_GEO_TEXT                   = 'SET_GEO_TEXT';
export const SET_GEO_POSITION_X             = 'SET_GEO_POSITION_X';
export const SET_GEO_POSITION_Y             = 'SET_GEO_POSITION_Y';

export const SET_LOOK_TEXT                  = 'SET_LOOK_TEXT';
export const SET_LOOK_COUNTER_VISIBILITY    = 'SET_LOOK_COUNTER_VISIBILITY';
export const SET_LOOK_STYLE1                = 'SET_LOOK_STYLE1';
export const SET_LOOK_STYLE2                = 'SET_LOOK_STYLE2';
export const SET_LOOK_STYLE3                = 'SET_LOOK_STYLE3';
export const SET_LOOK_SELECTED_TYPE         = 'SET_LOOK_SELECTED_TYPE';
export const SET_LOOK_COLOR_CODE            = 'SET_LOOK_COLOR_CODE';
export const SET_LOOK_COLOR_TEXT            = 'SET_LOOK_COLOR_TEXT';
export const SET_LOOK_COLOR_FONT_TEXT       = 'SET_LOOK_COLOR_FONT_TEXT';
export const SET_LOOK_COLOR_BG              = 'SET_LOOK_COLOR_BG';
export const SET_LOOK_REAL_COLORS           = 'SET_LOOK_REAL_COLORS';
export const SET_LOOK_BG_IMG                = 'SET_LOOK_BG_IMG';
export const SET_LOOK_BG_LOADED             = 'SET_LOOK_BG_LOADED';
export const SET_LOOK_BG_IMG_FULL           = 'SET_LOOK_BG_IMG_FULL';
export const SET_LOOK_BG_LOADED_FULL        = 'SET_LOOK_BG_LOADED_FULL';
export const SET_CP_IS_CODE                 = 'SET_CP_IS_CODE';
export const SET_CP_IS_BORDER               = 'SET_CP_IS_BORDER';
export const SET_CP_IS_TEXT                 = 'SET_CP_IS_TEXT';

export const SET_ADV_WITH_IMAGE_AND_TEXT    = 'SET_ADV_WITH_IMAGE_AND_TEXT';
export const SET_ADV_ICON_SELECTED          = 'SET_ADV_ICON_SELECTED';
export const SET_ADV_TITLE                  = 'SET_ADV_TITLE';
export const SET_ADV_LINK                   = 'SET_ADV_LINK';
export const SET_ADV_IMG_ICON               = 'SET_ADV_IMG_ICON';
export const SET_ADV_TOP_IMG_ICON           = 'SET_ADV_TOP_IMG_ICON';
export const SET_ADV_TOP_IMG_ICON_LOADED    = 'SET_ADV_TOP_IMG_ICON_LOADED';

export const SET_IS_TOO_LONG                = 'SET_IS_TOO_LONG';