import React, { Component } from 'react';
import '../../css/typography.css';

export default class Typography extends Component {
    render() {
        const sale = this.props.sale !== undefined ?  <div className="Typography--sale"><div className="Typography--icon Typography--icon-sale"/><div className="Typography--text">{ this.props.sale }</div></div> : '';
        const showAllAddresses = this.props.allAddresses !== undefined ? <div className="Typography--address-show-all-addresses"><a href={this.props.allAddresses} target="_blank">Показать все адреса</a></div> : '';

        return (
            <div className="Typography">
                <div className="Typography--title">{ this.props.title }</div>
                <div className="Typography--address"><div className="Typography--icon Typography--icon-address"/><div className="Typography--text">{ this.props.address }{ showAllAddresses }</div></div>
                <div className="Typography--site"><div className="Typography--icon Typography--icon-site"/><div className="Typography--text"><a href={"https://" + (this.props.url !== undefined ? this.props.url : this.props.site)} target="_blank">{ this.props.site }</a></div></div>
                { sale }
            </div>
        );
    }
}
