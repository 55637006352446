import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTextText } from "../store/settings/actions";
import { UPDATE_TIME } from "../services/_locals";
import {googleEvent, yandexEvent} from "../services/_functions";


class BlockText extends Component {

    state = {
        timeout: undefined,
    };

    updateGoogleTimeout;

    changeHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);

        this.setState({ timeout: setTimeout(() => this.props.dispatch(setTextText(val)), UPDATE_TIME) });

        clearTimeout(this.updateGoogleTimeout);
        this.updateGoogleTimeout = setTimeout(() => {
            googleEvent('Edit', 'Text', 'Data');
            yandexEvent('edit_text_data');
        }, 2500);
    };

    render() {
        return (
            <div className="White-block">
                <div className="White-block__info">
                    <p>QR-код — отличный способ поделиться инструкцией, составом или другими подробностями о продукте. Добавьте текст в QR-код и разместите его на товаре — клиенты смогут отсканировать код и прочитать всю информацию на своём телефоне.</p>
                    <p>Например, будет полезно зашифровать текст в QR-коде, если на упаковке мало места. Также можно перевести состав продукта с иностранного языка, закодировать перевод и добавить его к оригиналу.</p>
                </div>
                <div className="Form-textarea">
                    <div className="Form-textarea__name">Текст</div>
                    <textarea maxLength={ 110 } name="" id="" cols="" rows="4" placeholder="Введите текст..." onChange={ this.changeHandler } defaultValue={ this.props.textText }/>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        textText: state.settings.text.text,
    };
};

export default connect(mapStateToProps)(BlockText);
