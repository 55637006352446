import * as types from './actionTypes';

const initialState = {
    vkUser: {
        id: 0,
    },
};

export function vk(state = initialState, action = {}) {
    switch (action.type) {

        case types.SET_VK_USER:
            return {
                ...state,
                vkUser: action.vkUser,
            };

        default:
            return state;
    }
}