import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import '../../css/header.css';
import {googleEvent, yandexEvent} from "../services/_functions";
import ym from 'react-yandex-metrika';
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline';


class Header extends Component {

    selectMenu = (link) => {
        this.props.dispatch(push('/' + link));
    };

    renderMenuButton = (title, link, customClick) => {
        let click = () => { this.selectMenu(link); customClick() };
        return (
            <div className={ "Top-menu__button" + (this.props.selected === link ? ' Top-menu__button--active' : '')} onClick={ click } key={ 'top-menu-key-' + link }>
                <div className={ "Top-menu__button-icon Top-menu__button-icon--" + link }/>
                <div className="Top-menu__button-title">{ title }</div>
            </div>
        );
    };

    generateTopMenu = () => {
        let menu = [];
        menu.push(this.renderMenuButton('Ссылка',           'link',     () => { googleEvent('Content type', 'Link', 'Menu'); yandexEvent('content_type_link_menu'); }));
        menu.push(this.renderMenuButton('Настройки Wi-Fi',  'wifi',     () => { googleEvent('Content type', 'Wi-Fi', 'Menu'); yandexEvent('content_type_wi-fi_menu'); }));
        menu.push(this.renderMenuButton('Визитка',          'contact',  () => { googleEvent('Content type', 'Contact', 'Menu'); yandexEvent('content_type_contact_menu'); }));
        menu.push(this.renderMenuButton('Текст',            'text',     () => { googleEvent('Content type', 'Text', 'Menu'); yandexEvent('content_type_text_menu'); }));
        menu.push(this.renderMenuButton('Событие',          'event',    () => { googleEvent('Content type', 'Event', 'Menu'); yandexEvent('content_type_event_menu'); }));
        menu.push(this.renderMenuButton('Геолокация',       'geo',      () => { googleEvent('Content type', 'Geo', 'Menu'); yandexEvent('content_type_geo_menu'); }));
        return menu;
    };

    openAbout = () => {
        this.props.dispatch(push('/info'));
        googleEvent('What_is_QR', 'What_is_QR');
        yandexEvent('what_is_qr');
    };

    render() {
        return (
            <div className="Header">
                <div className="container">
                    <div className="Header__top-block">
                        <div className="Header__top-part">
                            <div className="Top-logo">
                                <div className="Top-logo__icon"/>
                                <div className="Top-logo__title">QR-генератор</div>
                            </div>
                            <div className="Header__account">
                                {/*<Button rounded color="transparent">Мои QR-коды</Button>*/}
                                {/*<div className="Header__account-ava"/>*/}
                            </div>
                        </div>
                        <div className="Header__description">
                            Помогите клиентам быстро найти Вашу страницу в интернете.<br/>
                            Используйте QR-коды — и делитесь ссылками на Ваши онлайн-ресурсы в офлайне.
                        </div>
                        <div className={ "Header__about" + (this.props.selectedUrl === 'about' ? ' Header__about-active' : '')} onClick={ this.openAbout }>Что такое QR?<span className="icon-triangle"/></div>
                    </div>
                    <div className="Top-menu">
                        { this.generateTopMenu() }
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        selectedUrl: state.globals.selectedUrl,
    };
};

export default connect(mapStateToProps)(Header);