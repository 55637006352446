import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../src/css/style.css';
import '../src/scss/form.scss';
import Header from "./js/components/Header";
import Footer from "./js/components/Footer";
import BlockQR from "./js/blocks/BlockQR";
import BlockAdvanced from "./js/blocks/BlockAdvanced";
import BlockLook from "./js/blocks/BlockLook";
import BlockLink from "./js/blocks/BlockLink";
import BlockWifi from "./js/blocks/BlockWifi";
import BlockContact from "./js/blocks/BlockContact";
import BlockText from "./js/blocks/BlockText";
import BlockEvent from "./js/blocks/BlockEvent";
import BlockGeo from "./js/blocks/BlockGeo";
import {setModal, setRetargetingClose, setSelectedUrl} from "./js/store/globals/actions";
import BlockAbout from "./js/blocks/BlockAbout";
import HeaderSmall from "./js/components/HeaderSmall";
import ReactGA from 'react-ga';
import {linkRelAlternate} from "./js/services/_functions";
import BlockRetargeting from "./js/blocks/BlockRetargeting";
import Cookies from 'universal-cookie';
import './scss/toast.scss';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isConnectionLost: false,
        }
    }

    componentWillMount() {
        const cookies = new Cookies();
        const isRetargetingClose = cookies.get('isRetargetingClose');
        if (isRetargetingClose) {
            this.props.dispatch(setRetargetingClose());
        }
    }

    componentDidMount = () => {
        window.addEventListener("offline", this.offlineHandler);
        window.addEventListener("online", this.offlineHandler);
        window.onpopstate = this.onBackOrForwardButtonEvent;
    };

    componentWillUnmount() {
        window.removeEventListener("offline", this.offlineHandler);
        window.removeEventListener("online", this.offlineHandler);
    }

    offlineHandler = (event) => {
        if (event.type === 'offline') {
            this.setState({ isConnectionLost: true });
        } else  {
            this.setState({ isConnectionLost: false });
        }
    }

    onBackOrForwardButtonEvent = () => {
        if (this.props.modal !== null && this.props.modal !== '') {
            this.props.dispatch(setModal(''));
        }
    };

    render() {
        let blocks = '';
        let selected = '';
        let isDefaultLayout = true;
        let isSmallHeader = false;
        let smallHeaderTitle = '';
        let h1 = '';

        switch (this.props.pageId) {
            case 'link':
                blocks = <BlockLink/>;
                selected = this.props.pageId;
                document.title = 'Генератор QR-кодов — создать код со ссылкой бесплатно';
                linkRelAlternate('link');
                h1 ='QR-код со ссылкой';
                break;

            case 'wifi':
                blocks = <BlockWifi/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код для подключения к Wi-Fi';
                linkRelAlternate('wifi');
                h1 ='QR-код для настройки wi-fi';
                break;

            case 'contact':
                blocks = <BlockContact/>;
                selected = this.props.pageId;
                document.title = 'Создать визитку с QR-кодом – онлайн генератор';
                linkRelAlternate('contact');
                h1 ='QR-визитка';
                break;

            case 'text':
                blocks = <BlockText/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код текста – онлайн зашифровка';
                linkRelAlternate('text');
                h1 ='QR-код текста';
                break;

            case 'event':
                blocks = <BlockEvent/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код события, мероприятия или календаря онлайн';
                linkRelAlternate('event');
                h1 ='QR-код для мероприятий';
                break;

            case 'geo':
                blocks = <BlockGeo/>;
                selected = this.props.pageId;
                document.title = 'Создать QR-код геолокации онлайн — поделиться координатами местоположения';
                linkRelAlternate('geo');
                h1 ='QR-код местоположения';
                break;

            case 'info':
                blocks = <BlockAbout/>;
                selected = this.props.pageId;
                isDefaultLayout = false;
                isSmallHeader = true;
                smallHeaderTitle = 'Что такое QR';
                document.title = 'Что такое QR-код';
                linkRelAlternate('info');
                break;

            default:
                blocks = <BlockLink/>;
                selected = 'link';
                document.title = 'Генератор QR-кодов — создать код со ссылкой бесплатно';
                linkRelAlternate('link');
                h1 ='QR-код со ссылкой';
                break;
        }

        ReactGA.pageview(`/${selected}`);
        this.props.dispatch(setSelectedUrl(selected));

        const defaultLayout =
            <React.Fragment>
                <div className="Parts__left">
                    { blocks }
                    { <BlockLook/> }
                    { <BlockAdvanced/> }
                    { !this.props.isRetargetingClose && <BlockRetargeting/> }
                </div>
                <div className="Parts__right"><BlockQR/></div>
            </React.Fragment>;

        return (
            <React.Fragment>
                { !isSmallHeader && <Header selected={ selected }/> }
                { isSmallHeader && <HeaderSmall title={ '' }/> }
                <div className="container">
                    <div className="Parts">
                        { isDefaultLayout && defaultLayout }
                        { !isDefaultLayout && blocks }
                    </div>
                    <Footer/>
                </div>
                { this.props.modal }
                { this.props.colorModal }
                <h1 className="hide-vis">{ h1 }</h1>

                {this.state.isConnectionLost === true && (
                    <div className="toast">
                        <div className="toast__inner">
                            Интернет-соединение потеряно, перезагрузите страницу
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        modal: state.globals.modal,
        colorModal: state.globals.colorModal,
        smallHeader: state.globals.smallHeader,
        isRetargetingClose: state.globals.isRetargetingClose,
    };
}

export default connect(mapStateToProps)(App);
