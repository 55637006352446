import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/footer.css';
import {setModal, setShowReviewResult} from "../store/globals/actions";
import ModalFeedback from "./ModalFeedback";


class Footer extends Component {

    openModal = () => {
        this.props.dispatch(setModal(<ModalFeedback/>))
    };

    hide = () => {
        this.props.dispatch(setShowReviewResult(false));
    };

    render() {
        return (
            <div className="Footer">
                <div className="Footer__review" onClick={ this.openModal }>Оставить отзыв или пожелание</div>
                <div className={ "Footer__review-result" + (this.props.isShowReviewResult ? ' Footer__review-result--show' : '')}>Ваш отзыв отправлен<div onClick={ this.hide } className="Footer__review-result_close"/></div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        isShowReviewResult: state.globals.isShowReviewResult,
    };
};

export default connect(mapStateToProps)(Footer);