import * as types from './actionTypes';


export const setLinkUrl = (url) => (
    {
        type: types.SET_LINK_URL,
        url,
    }
);

export const setWifiName = (name) => (
    {
        type: types.SET_WIFI_NAME,
        name,
    }
);

export const setWifiPass = (pass) => (
    {
        type: types.SET_WIFI_PASS,
        pass,
    }
);

export const setWifiType = (wifiType) => (
    {
        type: types.SET_WIFI_TYPE,
        wifiType,
    }
);

export const setContactFirstName = (firstName) => (
    {
        type: types.SET_CONTACT_FIRST_NAME,
        firstName,
    }
);

export const setContactMidName = (midName) => (
    {
        type: types.SET_CONTACT_MID_NAME,
        midName,
    }
);

export const setContactLastName = (lastName) => (
    {
        type: types.SET_CONTACT_LAST_NAME,
        lastName,
    }
);

export const setContactPosition = (position) => (
    {
        type: types.SET_CONTACT_POSITION,
        position,
    }
);

export const setContactCompany = (company) => (
    {
        type: types.SET_CONTACT_COMPANY,
        company,
    }
);

export const setContactPhone = (phone) => (
    {
        type: types.SET_CONTACT_PHONE,
        phone,
    }
);

export const setContactEmail = (email) => (
    {
        type: types.SET_CONTACT_EMAIL,
        email,
    }
);

export const setContactUrl = (url) => (
    {
        type: types.SET_CONTACT_URL,
        url,
    }
);

export const setTextText = (text) => (
    {
        type: types.SET_TEXT_TEXT,
        text,
    }
);

export const setEventTitle = (title) => (
    {
        type: types.SET_EVENT_TITLE,
        title,
    }
);

export const setEventStartDate = (startDate) => (
    {
        type: types.SET_EVENT_START_DATE,
        startDate,
    }
);

export const setEventStartTimeHours = (startTimeHours) => (
    {
        type: types.SET_EVENT_START_TIME_HOURS,
        startTimeHours,
    }
);

export const setEventStartTimeMinutes = (startTimeMinutes) => (
    {
        type: types.SET_EVENT_START_TIME_MINUTES,
        startTimeMinutes,
    }
);

export const setEventEndDate = (endDate) => (
    {
        type: types.SET_EVENT_END_DATE,
        endDate,
    }
);

export const setEventEndTimeHours = (endTimeHours) => (
    {
        type: types.SET_EVENT_END_TIME_HOURS,
        endTimeHours,
    }
);

export const setEventEndTimeMinutes = (endTimeMinutes) => (
    {
        type: types.SET_EVENT_END_TIME_MINUTES,
        endTimeMinutes,
    }
);

export const setEventDescription = (description) => (
    {
        type: types.SET_EVENT_DESCRIPTION,
        description,
    }
);

export const setGeoText = (text) => (
    {
        type: types.SET_GEO_TEXT,
        text,
    }
);

export const setGeoPositionX = (positionX) => (
    {
        type: types.SET_GEO_POSITION_X,
        positionX,
    }
);

export const setGeoPositionY = (positionY) => (
    {
        type: types.SET_GEO_POSITION_Y,
        positionY,
    }
);

export const setLookText = (text, isTextChangedByUser = true) => (
    {
        type: types.SET_LOOK_TEXT,
        text,
        isTextChangedByUser,
    }
);

export const setLookSelectedType = (selectedType) => (
    {
        type: types.SET_LOOK_SELECTED_TYPE,
        selectedType,
    }
);

export const setLookStyle1 = (selectedStyle1) => (
    {
        type: types.SET_LOOK_STYLE1,
        selectedStyle1,
    }
);

export const setLookStyle2 = (selectedStyle2) => (
    {
        type: types.SET_LOOK_STYLE2,
        selectedStyle2,
    }
);

export const setLookStyle3 = (selectedStyle3) => (
    {
        type: types.SET_LOOK_STYLE3,
        selectedStyle3,
    }
);

export const setLookCPIsCode = (isCode) => (
    {
        type: types.SET_CP_IS_CODE,
        isCode,
    }
);

export const setLookCPIsBorder = (isBorder) => (
    {
        type: types.SET_CP_IS_BORDER,
        isBorder
    }
);

export const setLookCPIsText = (isText) => (
    {
        type: types.SET_CP_IS_TEXT,
        isText,
    }
);

export const setLookColorCode = (colorCode) => (
    {
        type: types.SET_LOOK_COLOR_CODE,
        colorCode,
    }
);

export const setLookColorText = (colorText) => (
    {
        type: types.SET_LOOK_COLOR_TEXT,
        colorText,
    }
);

export const setLookColorFontText = (colorFontText) => (
    {
        type: types.SET_LOOK_COLOR_FONT_TEXT,
        colorFontText,
    }
);

export const setLookColorBg = (colorBg) => (
    {
        type: types.SET_LOOK_COLOR_BG,
        colorBg,
    }
);

export const setLookRealColors = () => (
    {
        type: types.SET_LOOK_REAL_COLORS,
    }
);

export const setLookBgImage = (bgImage) => (
    {
        type: types.SET_LOOK_BG_IMG,
        bgImage,
    }
);

export const setLookBgLoaded = (isBgLoaded) => (
    {
        type: types.SET_LOOK_BG_LOADED,
        isBgLoaded,
    }
);


export const setLookBgImageFull = (bgImageFull) => (
    {
        type: types.SET_LOOK_BG_IMG_FULL,
        bgImageFull,
    }
);

export const setLookBgLoadedFull = (isBgLoadedFull) => (
    {
        type: types.SET_LOOK_BG_LOADED_FULL,
        isBgLoadedFull,
    }
);

export const setAdvancedWithImageAndText = (withImageAndText) => (
    {
        type: types.SET_ADV_WITH_IMAGE_AND_TEXT,
        withImageAndText,
    }
);


export const setAdvancedIconSelected = (iconSelected, byUser = true) => (
    {
        type: types.SET_ADV_ICON_SELECTED,
        iconSelected,
        byUser,
    }
);

export const setAdvancedTitle = (title) => (
    {
        type: types.SET_ADV_TITLE,
        title,
    }
);

export const setAdvancedLink = (link) => (
    {
        type: types.SET_ADV_LINK,
        link,
    }
);

export const setAdvancedIcon = (iconUploaded) => (
    {
        type: types.SET_ADV_IMG_ICON,
        iconUploaded,
    }
);

export const setAdvancedTopIcon = (iconUploaded) => (
    {
        type: types.SET_ADV_TOP_IMG_ICON,
        iconUploaded,
    }
);

export const setAdvancedTopIconLoaded = (isTopIconLoaded) => (
    {
        type: types.SET_ADV_TOP_IMG_ICON_LOADED,
        isTopIconLoaded,
    }
);

export const setIsTooLongQR = (isTooLongQR) => (
    {
        type: types.SET_IS_TOO_LONG,
        isTooLongQR,
    }
);

export const setLookCounterVisibility = (isCounterVisible) => (
    {
        type: types.SET_LOOK_COUNTER_VISIBILITY,
        isCounterVisible,
    }
);